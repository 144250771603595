import { Button, Checkbox, Form, Input, Modal, Spin, DatePicker, Radio, Select } from 'antd';
import { bool, func, string } from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import { CloseModalSVG } from 'components/iconsSVG/close';
import { SadFaceSVG } from 'components/iconsSVG/sadFace';
import { ChevronSVG } from 'components/iconsSVG/chevron';
import { getMarket, getLang } from 'utils/helpers';
import { useI18n } from 'context/i18n';
import { PhoneInput } from 'components/base';
import FormInput from 'components/formInput';
import { subscribeTwsEvent, unsubscribeTwsEvent, conditionsTwsEvent, enrichTwsEvent } from 'actions/general';
import { checkNewsletterStatus } from 'actions/users';
import { ThankYouMessage } from 'components/thankYouMessage';
import ProtectionDataModal from 'components/protectionDataModal';
import { getConfigByLocale } from 'utils/getConfigByLocale';
import PrivacyPolicy from 'components/privacyPolicy';
import { PrivacyPolicyText } from 'components/legalTexts';

import { IS_NNORMAL } from 'utils/constants/system';

import { useIsMounted, useLocale, useValidate } from 'hooks';
import { isBlank } from 'utils/StringUtils';

import newsLetterStyles from 'components/newsletterModal/style.module.css';

import styles from './style.module.css';

const SHOW_ENRICHMENT = false;
// para poder desactivar toda la funcionalidad fácilmente
// no querían ni config ni param en url

const EventsTwsModal = ({
  loading = false,
  modalTwsVisible = false,
  setModalTwsVisible = () => true,
  onFinishOk = () => true,
  id = '1',
  type = 'subscribe',
  email = '',
}) => {
  const { locale, t, profileData, countries, localeConfigs } = useI18n();
  const parsedLocale = useLocale();
  const { phonePrefixes } = profileData;
  const validate = useValidate();
  const isMounted = useIsMounted();
  const [dataModalVisible, setDataModalVisible] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentTwsConditionsUrl, setCurrentTwsConditionsUrl] = useState('#');
  const [error, setError] = useState(null);
  const [loadingEmailRequest, setLoadingEmailRequest] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [thankYouType, setThankYouType] = useState('eventSuscribedUserNew');
  const [subscribed, setSubsribed] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(getMarket(locale));
  const [selectedLang, setSelectedLang] = useState(getLang(locale));
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [selectedSize, setSelectedSize] = useState(null);
  const [currentPhone, setCurrentPhone] = useState('');
  const [dateValue, setDateValue] = useState();
  const [storeContact, setStoreContact] = useState({
    events: false,
    promotions: false,
    launches: false,
  });

  const [translations, setTranslations] = useState({});
  const [emailForm] = Form.useForm();
  const [AdditionalForm] = Form.useForm();

  const localeFormat = getConfigByLocale(locale, localeConfigs);

  const countryRef = useRef();
  const langRef = useRef();

  const getLangsFromCountry = (country) => {
    const foundCountry = countries.find((item) => item.id === country);

    if (foundCountry === undefined) {
      return [];
    }

    return foundCountry.languages;
  };

  const handleChangeCountry = (country) => {
    setSelectedCountry(country);
    const langsInCountry = getLangsFromCountry(country);
    if (!langsInCountry.some(({ id: langId }) => langId === selectedLang)) {
      setSelectedLang(langsInCountry[0].id);
      AdditionalForm.setFieldsValue({ language: langsInCountry[0].id });
    }
  };

  const handleSearchCountry = (value) => {
    const result = countries.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));

    setFilteredCountries(result);
  };

  const validateAndSetDate = (previousDate, dateString) => {
    const formValue = AdditionalForm.getFieldValue('birthDate');
    if (dateString === undefined && formValue !== undefined) {
      // viene de onBlur, ProfileForm tiene el valor correcto
      dateString = formValue;
    }

    const formattedValue = moment(dateString, localeFormat.dateFormat);

    if (formattedValue._isValid && formattedValue > moment().startOf('day')) {
      // nueva key?
      setDateValue(formattedValue);
      AdditionalForm.setFields([
        {
          name: 'birthDate',
          errors: [t('validation', 'invalid.date', 'Please, select a valid date')],
        },
      ]);
      return false;
    }

    if (formattedValue._isValid) {
      AdditionalForm.setFields([
        {
          name: 'birthDate',
          value: formattedValue,
          errors: [],
        },
      ]);
      AdditionalForm.validateFields();
      setDateValue(formattedValue);
      return true;
    }

    setDateValue('');
    AdditionalForm.setFields([
      {
        name: 'birthDate',
        errors: [t('newsletter', 'error.fecha.incorrecta.registro')],
      },
    ]);
    return false;
  };

  useEffect(() => {
    const getConditionsTwsEventHandler = async () => {
      if (modalTwsVisible) {
        try {
          const rsConditions = await conditionsTwsEvent({ profile: locale, eventId: id });
          let eventConditions = rsConditions.urlTc;
          if (isMounted()) {
            setTranslations(rsConditions.translations);
            if (eventConditions && eventConditions.includes('{lang}')) {
              eventConditions = eventConditions.replace('{lang}', parsedLocale.language.toUpperCase());
            }
            if (eventConditions) {
              setCurrentTwsConditionsUrl(eventConditions);
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    };

    getConditionsTwsEventHandler();
  }, [modalTwsVisible]);

  const onUpdate = async (values) => {
    try {
      setLoadingEmailRequest(true);
      let phonePrefix = null;

      if (values?.preffix) {
        const realPrefix = phonePrefixes.find((item) => item.id === values.preffix);

        if (realPrefix) {
          [phonePrefix] = realPrefix.name.split('(').pop().split(')');
        }
      }

      if (phonePrefix === null) {
        // no ha tocado el prefijo
        const defaultPrefix = phonePrefixes.find((item) => item.id === parsedLocale.country);

        if (defaultPrefix) {
          [phonePrefix] = defaultPrefix.name.split('(').pop().split(')');
        }
      }

      if (dateValue === undefined) {
        AdditionalForm.setFields([
          {
            name: 'date',
            errors: [t('newsletter', 'error.fecha.incorrecta.registro')],
          },
        ]);
        setLoadingEmailRequest(false);
      } else {
        const payload = {
          ...values,
          email: currentEmail,
          eventId: Number(id),
          date: dateValue?.format('L'),
          storeContact,
          phonePrefix,
          country: selectedCountry,
          language: selectedLang,
        };

        delete payload.birthDate;
        delete payload['store-contact'];

        await enrichTwsEvent({ profile: locale, payload });

        setLoadingEmailRequest(false);
        setShowThankYou(true);
      }

      return true;
    } catch (e) {
      setLoadingEmailRequest(false);
      console.error(e);
      return false;
    }
  };

  const onFinish = async (values) => {
    setLoadingEmailRequest(true);
    const payload = {
      eventId: Number(id),
      name: values.name || '',
      email: values.email,
      phone: '',
      profile: locale,
    };

    try {
      setCurrentEmail(values.email);
      const newsletterRs = await checkNewsletterStatus(locale, values.email);

      if (!newsletterRs?.newsletterSubscribed && currentEmail !== values.email) {
        setLoadingEmailRequest(false);
        setIsNewUser(true);
      } else {
        const result = await subscribeTwsEvent(payload);

        if (result) {
          const showJustAdded = true;
          const userIdientified = true;
          onFinishOk(showJustAdded, userIdientified);
        }

        setLoadingEmailRequest(false);
        if (result?.message === 'already') {
          setThankYouType('eventSuscribedAlready');
        } else if (result?.message === 'full') {
          setThankYouType('eventSuscribedFull');
        } else {
          setThankYouType(newsletterRs?.newsletterSubscribed ? 'eventSuscribedUserExists' : 'eventSuscribedUserNew');
        }

        if (SHOW_ENRICHMENT === true && result?.message !== 'full') {
          setSubsribed(true);
        } else {
          setIsNewUser(false);
          setShowThankYou(true);
        }
      }
    } catch (rqError) {
      console.error(rqError);
      let errorMessage = 'KO: Unexpected Error';
      if (rqError?.data?.errors?.length > 0) {
        errorMessage = rqError?.data?.errors[0].message || 'KO: Unexpected Error';
      }
      if (rqError?.data?.message === 'blocked') {
        errorMessage = t('generico', 'tws.blocked.event');
      }
      setError(errorMessage);
      setLoadingEmailRequest(false);
    }
  };
  const onUnsubscribe = async () => {
    setLoadingEmailRequest(true);
    const payload = {
      eventId: Number(id),
      email,
      profile: locale,
    };

    try {
      await unsubscribeTwsEvent(payload);
      setLoadingEmailRequest(false);
      setShowThankYou(true);
      setThankYouType('eventUnsuscribed');
    } catch (rqError) {
      console.error(rqError);
      let errorMessage = 'KO: Unexpected Error';
      if (rqError?.data?.errors?.length > 0) {
        errorMessage = rqError?.data?.errors[0].message || 'KO: Unexpected Error';
      }
      setError(errorMessage);
      setLoadingEmailRequest(false);
    }
  };
  const isLoading = loadingEmailRequest || loading;
  const twsTermsConditionsLabel = translations?.extraInfo;
  const twsTermsConditionsLabelHasValue = !isBlank(twsTermsConditionsLabel);
  const modalWrapperClass = subscribed && !showThankYou ? `${styles.modalWrapper} ${styles.modalEnrichment}` : styles.modalWrapper;

  const datePickerProps = {
    changeOnBlur: true,
    allowClear: false,
    format: localeFormat.dateFormat,
    disabledDate: (current) => current && current > moment().startOf('day'),
    onBlur: validateAndSetDate,
    onChange: validateAndSetDate,
    value: dateValue,
  };

  return (
    <Modal
      title=""
      visible={modalTwsVisible}
      footer={null}
      onCancel={!isLoading ? () => setModalTwsVisible(false) : () => true}
      wrapClassName={modalWrapperClass}
      destroyOnClose={true}
      closeIcon={<CloseModalSVG />}
      maskClosable={!isLoading}
      keyboard={!isLoading}
      closable={!isLoading}
    >
      <Spin spinning={isLoading}>
        <div className={styles.modalContent}>
          <div className={`${styles.content} ${showThankYou ? styles.thankYouContent : ''}`}>
            {type === 'subscribe' && (
              <>
                {showThankYou && <ThankYouMessage type={thankYouType} className={styles.thankYouMessage} />}
                {!IS_NNORMAL && showThankYou && thankYouType !== 'eventSuscribedFull' && (
                  <>
                    <p className={styles.shareText}>{translations.share ?? t('generico', 'modal.events.tws.share')}</p>
                    <div className={styles.shareDiv}>
                      <a
                        href={`https://web.whatsapp.com/send?text=https%3A%2F%2Fwww.camper.com%2F${locale}%2Fcontent%2Fnews%23${id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets-new/img/share/whatsapp.png" alt="whatsapp share" width={28} height={28} />
                      </a>
                      <a
                        href={`mailto:?subject=sharing link\n&body=I'm going to this event: https://www.camper.com/${locale}/content/news#${id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets-new/img/share/email.png" alt="email share" width={28} height={28} />
                      </a>
                    </div>
                  </>
                )}
                {!showThankYou && (
                  <>
                    {subscribed ?
                      <>
                        <div className="flex justify-center">
                          <img src="/assets-new/newsletter-subscription-socks.gif" alt="Subscription OK!" height={70} />
                        </div>
                        <p className={styles.title}>{t('generico', 'modal.event.tws.clienteling.title')}</p>
                        <p
                          className={styles.subtitle}
                          dangerouslySetInnerHTML={{
                            __html: t('generico', 'modal.event.tws.clienteling.subtitle'),
                          }}
                        />
                      </>
                    : <>
                        <img
                          src={IS_NNORMAL ? '/assets-new/logo-nnormal.svg' : '/assets-new/img/tws/tws_events.svg'}
                          alt={'Members Only Logo'}
                          width={IS_NNORMAL ? 200 : 80}
                          height={80}
                        />
                        <p className={styles.title}>
                          {isNewUser ? (translations.titleNotMember ?? t('generico', 'modal.events.tws.title.not.member')) : (translations.title ?? '')}
                        </p>
                        <p
                          className={styles.subtitle}
                          dangerouslySetInnerHTML={{
                            __html:
                              isNewUser ?
                                (translations.subtitleNotMember ?? t('generico', 'modal.events.tws.subtitle.not.member'))
                              : (translations.subtitle ?? ''),
                          }}
                        />
                      </>
                    }
                    {subscribed === false ?
                      <Form
                        form={emailForm}
                        layout="vertical"
                        name="emailForm"
                        onFinish={onFinish}
                        requiredMark="optional"
                        scrollToFirstError
                        className={styles.emailForm}
                        initialValues={{ preffix: parsedLocale.country }}
                      >
                        {isNewUser && (
                          <Form.Item name="name">
                            <Input placeholder={t('compra.formulario', 'nombre')} autoComplete="name" maxLength={20} />
                          </Form.Item>
                        )}
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: t('validation', 'required.email', 'Please input your email'),
                            },
                            {
                              type: 'string',
                              pattern: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,
                              message: '',
                            },
                          ]}
                          required
                        >
                          <Input placeholder={t('generico', 'input.email.placeholder', 'Enter your email')} type="email" autoComplete="false" />
                        </Form.Item>
                        {false && ( // por ahora deciden no pedirlo
                          <Form.Item
                            name="telephone"
                            label={t('generico', 'input.fecha.telefono')}
                            className={styles.formPhone}
                            rules={[
                              {
                                required: false,
                              },
                            ]}
                          >
                            <div className={styles.phoneWrapper}>
                              <PhoneInput
                                info={profileData}
                                placeholder={t('generico', 'input.fecha.telefono', 'Phone')}
                                rules={validate('telephone')}
                                className={styles.newsletterPhone}
                                withFloatLabel={false}
                              />
                            </div>
                          </Form.Item>
                        )}
                        <div className={styles.termsAndConditionsDiv}>
                          {isNewUser ?
                            <Form.Item>
                              <div className={styles.privacyWrapper}>
                                <p className={styles.infoLink} onClick={() => setDataModalVisible(true)}>
                                  {t(
                                    'generico',
                                    !IS_NNORMAL ? 'camper.newsletter.proteccion.datos' : 'newsletter.proteccion.datos',
                                    'Data protection information',
                                  )}
                                </p>
                              </div>
                            </Form.Item>
                          : null}
                          {isNewUser && (
                            <Form.Item name="privacy" valuePropName="checked" rules={validate('privacyPolicy')}>
                              <Checkbox
                                tabIndex="27"
                                className={parsedLocale.country !== 'TR' ? styles.privacyCheckbox : `${styles.privacyCheckbox} ${styles.withMargin}`}
                              >
                                {parsedLocale.country === 'US' ?
                                  <PrivacyPolicyText className={styles.privacyAgreement} />
                                : <span className={styles.privacyAgreement}>
                                    {t('compra.formulario', 'acepto.las')}&nbsp;
                                    <a href={profileData.urlPrivacyPolicy} target="_blank" rel="noreferrer">
                                      {t('generico', 'privacy.conditions', 'privacy conditions')}
                                    </a>
                                    &nbsp;
                                    {t('compra.formulario', 'become.member')}
                                    {parsedLocale.country === 'TR' && (
                                      <span>
                                        <br />
                                        {t('compra.formulario', 'informacion.comercial.tr')}
                                      </span>
                                    )}
                                  </span>
                                }
                              </Checkbox>
                            </Form.Item>
                          )}
                          <Form.Item name="suscribe-tws" valuePropName="checked" rules={validate('privacyPolicy')}>
                            <Checkbox className={styles.privacyCheckbox}>
                              <a href={currentTwsConditionsUrl} target="_blank" rel="noreferrer">
                                <span
                                  className={styles.privacyAgreement}
                                  dangerouslySetInnerHTML={{ __html: t('generico', 'accept.suscribe.tws.checkbox') }}
                                ></span>
                              </a>
                            </Checkbox>
                          </Form.Item>
                          {twsTermsConditionsLabelHasValue && (
                            <div className={styles.legalText} dangerouslySetInnerHTML={{ __html: translations?.extraInfo }} />
                          )}
                        </div>

                        {error && <span className="error">{error}</span>}

                        <Form.Item className={styles.submitItem}>
                          <Button type="default" htmlType="submit" className={styles.submitBtn} block size="large">
                            {translations.subscribe ?? t('generico', 'tws.subscribe', 'Subscribe')}
                          </Button>
                        </Form.Item>
                      </Form>
                    : <Form
                        form={AdditionalForm}
                        layout="vertical"
                        name="additionalForm"
                        onFinish={onUpdate}
                        // onFocus={handleUpdateFocus}
                        // onFieldsChange={handleFormError}
                        requiredMark=""
                        scrollToFirstError
                        initialValues={{
                          country: selectedCountry,
                          language: selectedLang,
                          telephone: currentPhone,
                          /* email: filledEmail,
                        telephone: phoneValue,*/
                        }}
                      >
                        <div className={styles.enrichmentForm}>
                          <FormInput label={t('compra.formulario', 'nombre')} name="name" rules={validate('name')} required>
                            <Input placeholder={t('compra.formulario', 'nombre')} autoComplete="name" maxLength={20} />
                          </FormInput>

                          <Form.Item
                            name="telephone"
                            label={t('generico', 'input.fecha.telefono')}
                            className={newsLetterStyles.formPhone}
                            rules={validate('telephone')}
                            required
                          >
                            <div className={newsLetterStyles.phoneWrapper}>
                              <PhoneInput
                                info={{ phonePrefixes }}
                                placeholder={t('generico', 'input.fecha.telefono', 'Phone')}
                                rules={validate('telephone')}
                                className={styles.newsletterPhone}
                                withFloatLabel={false}
                              />
                            </div>
                          </Form.Item>

                          <FormInput label={t('generico', 'modal.events.tws.address')} name="address" rules={validate('address')} required>
                            <Input placeholder={t('generico', 'modal.events.tws.address')} />
                          </FormInput>

                          <FormInput
                            label={t('generico', 'modal.events.tws.residence.barcelona')}
                            name="residenceBarcelona"
                            rules={validate('requiredOption')}
                            required
                          >
                            <Radio.Group>
                              <Radio value="true">{t('generico', 'modal.events.tws.yes')}</Radio>
                              <Radio value="false">{t('generico', 'modal.events.tws.no')}</Radio>
                            </Radio.Group>
                          </FormInput>

                          <FormInput name="gender" label={t('generico', 'modal.events.tws.interested.in')} rules={validate('requiredOption')} required>
                            <Radio.Group>
                              <Radio value="W">{t('generico', 'input.mujer', 'Women')}</Radio>
                              <Radio value="M">{t('generico', 'input.hombre', 'Men')}</Radio>
                            </Radio.Group>
                          </FormInput>

                          <FormInput
                            name="birthDate"
                            label={t('generico', 'input.fecha.nacimiento', 'Date of birth')}
                            required
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const birthDate = moment(getFieldValue('birthDate'), localeFormat.dateFormat);
                                  if (!value || birthDate <= moment().startOf('day')) {
                                    AdditionalForm.setFieldsValue({
                                      birthDate,
                                    });
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error(t('validation', 'invalid.date', 'Please, select a valid date')));
                                },
                              }),
                            ]}
                          >
                            <div className={newsLetterStyles.datePicker}>
                              <DatePicker {...datePickerProps} />
                            </div>
                          </FormInput>

                          <FormInput name="country" className={newsLetterStyles.selector} label={t('newsletter', 'pais', '**Country of residence')} required>
                            <Select
                              ref={countryRef}
                              showSearch
                              // value={selectedCountry}
                              onSearch={handleSearchCountry}
                              filterOption={false}
                              onChange={(val) => {
                                countryRef.current.blur();
                                handleChangeCountry(val);
                              }}
                              placeholder={t('modal.idiomas', 'select.country', 'Select a country')}
                              key={selectedCountry}
                              className={styles.selectInput}
                              suffixIcon={<ChevronSVG />}
                              defaultValue={getMarket(locale)}
                              value={selectedCountry}
                            >
                              {filteredCountries.map((country) => (
                                <Select.Option key={country.id} className={newsLetterStyles.selectItem}>
                                  <p>{country.name}</p>
                                </Select.Option>
                              ))}
                            </Select>
                          </FormInput>
                          <FormInput name="language" className={newsLetterStyles.selector} label={t('newsletter', 'idioma', '**Language')} required>
                            <Select
                              ref={langRef}
                              disabled={!selectedCountry}
                              autoComplete="false"
                              onChange={(val) => {
                                langRef.current.blur();
                                setSelectedLang(val);
                              }}
                              placeholder={t('modal.idiomas', 'select.language', 'Select a language')}
                              className={styles.selectInput}
                              suffixIcon={<ChevronSVG />}
                              options={getLangsFromCountry(selectedCountry).map((language) => ({
                                value: language.id,
                                label: language.name,
                                className: `${newsLetterStyles.selectItem}`,
                              }))}
                              defaultValue={getLang(locale)}
                              value={selectedLang}
                            >
                              {getLangsFromCountry(selectedCountry).map((language) => (
                                <Select.Option key={language.id} value={language.id} className={newsLetterStyles.selectItem}>
                                  <p>{language.name}</p>
                                </Select.Option>
                              ))}
                            </Select>
                          </FormInput>

                          <FormInput name="size" label={t('generico', 'modal.event.tws.size')} required rules={[{ required: true }]}>
                            <Input placeholder={t('generico', 'modal.event.tws.size')} maxLength={10} />
                          </FormInput>
                        </div>

                        <div className={`flex flex-col items-center ${styles.checkboxesWrapper}`}>
                          <FormInput
                            label={t('generico', 'modal.events.tws.store.contact')}
                            name="store-contact"
                            valuePropName="checked"
                            required
                            rules={[
                              () => ({
                                validator() {
                                  return storeContact.events === true || storeContact.promotions === true || storeContact.launches === true ?
                                      Promise.resolve()
                                    : Promise.reject(new Error(t('validation', 'required.select.option', 'Please select an option.')));
                                },
                              }),
                            ]}
                          >
                            <div className={styles.storeContact}>
                              <Checkbox
                                className={styles.privacyCheckbox}
                                onChange={(event) => {
                                  setStoreContact((current) => ({ ...current, events: event.target.checked }));
                                }}
                              >
                                <span
                                  className={styles.privacyAgreement}
                                  dangerouslySetInnerHTML={{ __html: t('generico', 'modal.events.tws.store.contact.events') }}
                                />
                              </Checkbox>
                              <Checkbox
                                className={styles.privacyCheckbox}
                                onChange={(event) => {
                                  setStoreContact((current) => ({ ...current, promotions: event.target.checked }));
                                }}
                              >
                                <span
                                  className={styles.privacyAgreement}
                                  dangerouslySetInnerHTML={{ __html: t('generico', 'modal.events.tws.store.contact.promotions') }}
                                />
                              </Checkbox>
                              <Checkbox
                                className={styles.privacyCheckbox}
                                onChange={(event) => {
                                  setStoreContact((current) => ({ ...current, launches: event.target.checked }));
                                }}
                              >
                                <span
                                  className={styles.privacyAgreement}
                                  dangerouslySetInnerHTML={{ __html: t('generico', 'modal.events.tws.store.contact.launches') }}
                                />
                              </Checkbox>
                            </div>
                          </FormInput>

                          <PrivacyPolicy
                            origin="newletter"
                            checkBoxClass={styles.privacyCheckbox}
                            hideConditions
                            overrideLabel={t('generico', 'become.clienteling')}
                          />

                          <Form.Item className={styles.updateItem}>
                            <Button
                              type="default"
                              htmlType="submit"
                              className={styles.updateBtn}
                              // onClick={handleClickSubmit}
                            >
                              {t('generico', 'modal.events.tws.update')}
                            </Button>
                          </Form.Item>
                          <p onClick={() => setShowThankYou(true)} className={newsLetterStyles.cancel}>
                            {t('generico', 'boton.cancelar', 'Cancel')}
                          </p>
                        </div>
                      </Form>
                    }
                  </>
                )}
              </>
            )}
            {type === 'unsubscribe' && (
              <>
                {showThankYou && <ThankYouMessage type={thankYouType} className={styles.thankYouMessage} />}
                {!showThankYou && (
                  <>
                    <div className={styles.svgSad}>
                      {IS_NNORMAL ?
                        <img src={'/assets-new/logo-nnormal.svg'} alt={'Nnormal Logo'} width={200} height={80} />
                      : <SadFaceSVG width={50} height={50} />}
                    </div>
                    <div
                      className={`${styles.subtitle} ${styles.unsubscribe}`}
                      dangerouslySetInnerHTML={{
                        __html: translations.unsubscribeConfirm ?? t('generico', 'modal.events.tws.unsubscribe.confirm'),
                      }}
                    />
                    <p className={`${styles.subtitle} ${styles.unsubscribe} ${styles.subtext}`}>
                      {translations.unsubscribeConfirmText ?? t('generico', 'modal.events.tws.unsubscribe.confirm.text')}
                    </p>
                    <Button type="default" className={styles.submitBtn} block size="large" onClick={onUnsubscribe}>
                      {translations.unsubscribe ?? t('generico', 'tws.unsubscribe', 'Unsubscribe')}
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Spin>
      <ProtectionDataModal visible={dataModalVisible} onCancel={() => setDataModalVisible(false)} origin={'newsletter'} />
    </Modal>
  );
};

EventsTwsModal.propTypes = {
  loading: bool,
  modalTwsVisible: bool,
  setModalTwsVisible: func,
  onFinishOk: func,
  id: string,
  type: string,
  email: string,
};

export default EventsTwsModal;
